<template>
  <v-container fill-height>
    <v-row justify="space-between">
      <v-col cols="12" md="5"> 
        <v-card class="elevation-12 text-center rounded-xl" color="#F0F1FF">
          <v-card-title class="mt-2">
            <v-img src="../assets/ulink.png" contain max-height="40"></v-img>
          </v-card-title>
          <v-card-subtitle class="mt-2">
            <h3>Ingresa:</h3>
          </v-card-subtitle>
          <v-card-text class="text-center">
            <v-form @submit.prevent="logIn(userLogin)">
              <v-container>
                <v-row>
                  <v-col cols="12">
                      <v-text-field 
                        v-model="userLogin.email" 
                        label="Usuario" 
                        shaped 
                        outlined 
                        prepend-icon="mdi-account"
                      >
                      </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="userLogin.password"
                      outlined 
                      shaped
                      prepend-icon="mdi-lock"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPass ? 'text' : 'password'"
                      label="Contraseña"
                      :error="false"
                      @click:append="showPass = !showPass"
                    >
                    </v-text-field>
                  </v-col>
                  <!--<v-col cols="12">-->
                    <v-alert v-if="error"
                      dense
                      border="left"
                      elevation="12"
                      icon="mdi-account-cancel"
                      shaped
                      type="error"
                      width="100%"
                    >{{error}}</v-alert> 
                  <!--</v-col>-->
                  <v-col cols="12">
                     <v-btn type="submit" block color="primary" elevation="8" rounded x-large>Ingresar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card> 
      </v-col>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-img
            alt="PromoKIN"
            class="shrink mt-1 hidden-sm-and-down ml-6"
            contain
            src="../assets/ulinkTel.png"
            cover
            max-height="350"
          />
        </v-col>
      </v-row>
    </v-row> 
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex"

export default {
  data() {
    return {
      userLogin: {
        email: '',
        password: ''
      },
      showPass: false,
    }
  },

  methods: {
    ...mapActions(['logIn'])
  },

  computed: {
    ...mapState(['error'])
  },

}
</script>